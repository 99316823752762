import { differenceInSeconds, fromUnixTime, isEqual, parseISO, startOfDay } from 'date-fns';
import map from 'lodash/fp/map';
import isNil from 'lodash/fp/isNil';
import sortBy from 'lodash/fp/sortBy';
import mapValues from 'lodash/fp/mapValues';

import { GeofenceAssetEvent, GeofenceAssetEventGroup } from '../types';
import { GeofenceEventsResponse } from './fetchGeofenceEvents';

const HISTORY_DURATION_IN_SECONDS = 72 * 60 * 60;

const createDayGroupIfNotPresent = (geofenceHistoryItemMap: { [p: number]: GeofenceAssetEvent[] }, date: Date) => {
    if (isNil(geofenceHistoryItemMap[date.getTime()])) {
        geofenceHistoryItemMap[date.getTime()] = [];
    }
};

export const mapGeofenceAssetEventGroups = (response: GeofenceEventsResponse): GeofenceAssetEventGroup[] => {
    const geofenceHistoryItemMap: { [date: number]: GeofenceAssetEvent[] } = {};
    const now = fromUnixTime(Date.now() / 1000);

    response.events.forEach((geofenceEvent) => {
        const { entry_date_time: entryDateTimeString, exit_date_time: exitDateTimeString } = geofenceEvent;
        const entryDateTime = parseISO(entryDateTimeString);
        const exitDateTime = exitDateTimeString ? parseISO(exitDateTimeString) : undefined;
        const entryDate = startOfDay(entryDateTime);
        const exitDate = exitDateTime ? startOfDay(exitDateTime) : undefined;
        const baseEvent = {
            assetId: geofenceEvent.asset_id,
            duration:
                geofenceEvent.duration !== undefined
                    ? geofenceEvent.duration * 60
                    : differenceInSeconds(now, entryDateTime),
        };

        if (exitDate && isEqual(entryDate, exitDate)) {
            createDayGroupIfNotPresent(geofenceHistoryItemMap, entryDate);
            geofenceHistoryItemMap[entryDate.getTime()].push({
                ...baseEvent,
                entryDateTime,
                exitDateTime,
            });
        } else {
            // Throw away entry events older than the target history time window
            if (entryDate && differenceInSeconds(now, entryDateTime) <= HISTORY_DURATION_IN_SECONDS) {
                createDayGroupIfNotPresent(geofenceHistoryItemMap, entryDate);
                geofenceHistoryItemMap[entryDate.getTime()].push({
                    ...baseEvent,
                    entryDateTime,
                    exitDateTime: undefined,
                });
            }
            if (exitDate) {
                createDayGroupIfNotPresent(geofenceHistoryItemMap, exitDate);
                geofenceHistoryItemMap[exitDate.getTime()].push({
                    ...baseEvent,
                    entryDateTime: undefined,
                    exitDateTime,
                });
            }
        }
    });

    const geofenceHistoryItemMapWithSortedEvents = mapValues((events) => {
        return sortBy((event) => event.exitDateTime || event.entryDateTime, events).reverse();
    }, geofenceHistoryItemMap);

    // @ts-ignore
    const unsortedGroups = map.convert({ cap: false })((geofenceAssetEvents: GeofenceAssetEvent[], date: number) => ({
        date: fromUnixTime(date / 1000),
        geofenceAssetEvents,
    }))(geofenceHistoryItemMapWithSortedEvents);

    return sortBy((item: GeofenceAssetEventGroup) => item.date)(unsortedGroups).reverse();
};
