import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import isEmpty from 'lodash/fp/isEmpty';

import { Dispatch, State } from '../../types';
import { useResizer } from './hooks/useResizer';
import { fetchAssets } from './fetchData/fetchAssets';
import { fetchGeofence } from './fetchData/fetchGeofence';
import { fetchGeofenceState } from './fetchData/fetchGeofenceState';
import { fetchGeofenceEvents } from './fetchData/fetchGeofenceEvents';
import { getAssets, getSelectedGeofenceIds } from './selector';
import { appActions } from './reducer';
import { geofencesSelectedType, setVisibleWidgetsType, setWidgetIdType } from './remoteActionTypes';

export const sendMessage = (message: any) => {
    const referrer = document.referrer;
    if (referrer) {
        window.parent.postMessage(message, referrer);
    }
};

const receiveMessage = (
    event: MessageEvent,
    setWidgetId: (widgetId: string) => void,
    setVisibleWidgets: (visibleWidgets: string[]) => void,
    setSelectedGeofences: (visibleGeofences: { ids: string[] }) => void,
) => {
    const actionType = event.data.type;
    if (!actionType) {
        return;
    }

    switch (actionType) {
        case setWidgetIdType:
            return setWidgetId(event.data.payload);
        case setVisibleWidgetsType:
            return setVisibleWidgets(event.data.payload);
        case geofencesSelectedType:
            return setSelectedGeofences(event.data.payload);
    }
};

export const MessageHandler = React.memo(
    ({
        assets,
        selectedGeofenceIds,
        loadAssets,
        loadGeofenceById,
        setSelectedGeofences,
        setWidgetId,
        setVisibleWidgets,
    }: Props) => {
        useEffect(() => {
            const listenerCallback = (event: MessageEvent) =>
                receiveMessage(event, setWidgetId, setVisibleWidgets, setSelectedGeofences);
            window.addEventListener('message', listenerCallback, false);
            return () => window.removeEventListener('message', listenerCallback, false);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        useResizer(sendMessage);

        useEffect(() => {
            if (isEmpty(assets)) {
                loadAssets();
            }
        }, [assets, loadAssets]);

        useEffect(() => {
            if (!isEmpty(selectedGeofenceIds)) {
                loadGeofenceById(selectedGeofenceIds[0]);
            }
        }, [selectedGeofenceIds, loadGeofenceById]);

        return null;
    },
);

type Props = ConnectedProps<typeof connector>;

const mapStateToProps = (state: State) => ({
    assets: getAssets(state),
    selectedGeofenceIds: getSelectedGeofenceIds(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setWidgetId: (widgetId: string) => dispatch(appActions.setWidgetId(widgetId)),
    setVisibleWidgets: (visibleWidgets: string[]) => dispatch(appActions.setVisibleWidgets(visibleWidgets)),
    setSelectedGeofences: (geofenceIds: { ids: string[] }) => dispatch(appActions.setSelectedGeofences(geofenceIds)),
    loadAssets: () => dispatch(fetchAssets()),
    loadGeofenceById: (geofenceId: string) => {
        dispatch(fetchGeofence(geofenceId));
        dispatch(fetchGeofenceState(geofenceId));
        dispatch(fetchGeofenceEvents(geofenceId));
    },
});
const connector = connect(mapStateToProps, mapDispatchToProps);
const MessageHandlerContainer = connector(MessageHandler);
export default MessageHandlerContainer;
