import './polyfills';

import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { handleLoginRedirect, main, store } from './configuration';

import { config } from './config';
import AppContainer from './features/app/AppContainer';
import { ErrorBoundary } from './features/app/ErrorBoundary';
import { createRoot } from 'react-dom/client';

const renderApplication = () => {
    const container = document.getElementById('root');
    if (!container) {
        throw new Error('Failed to find the root element');
    }
    const root = createRoot(container);

    root.render(
        <ErrorBoundary>
            <Provider store={store}>
                <BrowserRouter>
                    <Routes>
                        <Route path={'/*'} element={<AppContainer />} />
                    </Routes>
                </BrowserRouter>
            </Provider>
        </ErrorBoundary>,
    );
};

if (window.location.href.startsWith(config.login.redirectUri as string)) {
    handleLoginRedirect();
} else {
    main(renderApplication);
}
