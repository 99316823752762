import { FormattedDate } from 'react-intl';
import { formatDuration } from '../../../formatUtils';

export interface GeofenceAssetItem {
    readonly assetId: string;
    readonly assetType: string;
    readonly assetName?: string;
    readonly duration?: number;
    readonly entryDate?: Date;
}

type GeofenceCurrentAssetListItemProps = GeofenceAssetItem;

export const GeofenceCurrentAssetListItem = (props: GeofenceCurrentAssetListItemProps) => {
    return (
        <div
            className="display-flex justify-content-between align-items-center"
            data-testid={'GeofenceCurrentAssetListItem'}
        >
            <div className="line-height-125rel display-flex align-items-center padding-right-10">
                <span className={`rioglyph rioglyph-${props.assetType} margin-right-3`} />
                <span>{props.assetName}</span>
            </div>
            <div className="display-flex text-color-dark text-right text-size-12 white-space-nowrap">
                <div className="margin-left-10">
                    <span className="rioglyph rioglyph-time-alt margin-right-2" />
                    <span>{formatDuration(props.duration || 0)}</span>
                </div>
                <div className="margin-left-5">
                    <span className="rioglyph rioglyph-area-in margin-right-2" />
                    <span className="">
                        <FormattedDate
                            value={props.entryDate}
                            year={'numeric'}
                            month={'2-digit'}
                            day={'2-digit'}
                            hour={'2-digit'}
                            minute={'2-digit'}
                        />
                    </span>
                </div>
            </div>
        </div>
    );
};
