export interface Coordinates {
    latitude: number;
    longitude: number;
    address?: string;
}

export enum GeofenceType {
    Polygon = 'POLYGON',
    Circle = 'CIRCLE',
}

export interface Geofence {
    id: string;
    name: string;
    type: GeofenceType;
    active: boolean;
    category?: string;

    // polygon Geofence specific
    points?: Coordinates[];

    // circle Geofence specific
    center?: Coordinates;
    radius?: number;
}

export interface AssetInfo {
    assetId: string;
    assetName?: string;
    entryDate: Date;
}

export interface GeofenceState {
    exceptionAssetIds: string[];
    geofenceId: string;
    assetInfoList: AssetInfo[];
    warningAssetIds: string[];
}

export interface GeofenceEvent {
    geofenceVehicleEventId?: string;
    geofenceId: string;
    vehicleId: string;
    vehicleName?: string;
    vehicleType?: string;
    entryDateTime: Date;
    exitDateTime?: Date;
}

export interface GeofenceAssetEvent {
    readonly assetId: string;
    readonly assetName?: string;
    readonly assetType?: string;
    readonly duration?: number;
    readonly entryDateTime?: Date;
    readonly exitDateTime?: Date;
}

export interface GeofenceAssetEventGroup {
    readonly date: Date;
    readonly geofenceAssetEvents: readonly GeofenceAssetEvent[];
}

export type GeofenceEvents = GeofenceEvent[];

export interface Asset {
    id: string;
    name: string;
    type: string;
}

export type Assets = Asset[];
