import { Assets, Geofence, GeofenceAssetEventGroup, GeofenceState } from './types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AppState {
    widgetId?: string;
    visible: boolean;
    sessionExpiredAcknowledged: boolean;
    selectedGeofenceIds: string[];
    fetchAssetsRequested: boolean;
    fetchAssetsFailed: boolean;
    assets: Assets;
    fetchGeofenceRequested: boolean;
    fetchGeofenceFailed: boolean;
    geofence: Geofence | undefined;
    fetchGeofenceStateRequested: boolean;
    fetchGeofenceStateFailed: boolean;
    geofenceState: GeofenceState | undefined;
    fetchGeofenceEventsRequested: boolean;
    fetchGeofenceEventsFailed: boolean;
    geofenceAssetEventGroups: GeofenceAssetEventGroup[];
    lastSuccessfulFetchTimestamp: number | undefined;
}

export const initialState: AppState = {
    widgetId: undefined,
    visible: false,
    sessionExpiredAcknowledged: false,
    selectedGeofenceIds: [],
    fetchAssetsRequested: false,
    fetchAssetsFailed: false,
    assets: [],
    fetchGeofenceRequested: false,
    fetchGeofenceFailed: false,
    geofence: undefined,
    fetchGeofenceStateRequested: false,
    fetchGeofenceStateFailed: false,
    geofenceState: undefined,
    fetchGeofenceEventsRequested: false,
    fetchGeofenceEventsFailed: false,
    geofenceAssetEventGroups: [],
    lastSuccessfulFetchTimestamp: undefined,
};

type SetWidgetIdPayload = string;
type SetVisibleWidgetsPayload = string[];

interface SelectedGeofences {
    ids: string[];
}

// export default reducer;
const name = 'app';
const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers: {
        hideSessionExpiredDialog: (state: AppState) => {
            state.sessionExpiredAcknowledged = true;
        },
        fetchAssetsRequested: (state: AppState) => {
            state.fetchAssetsRequested = true;
            state.fetchAssetsFailed = false;
        },
        fetchAssetsSucceeded: (state: AppState, action: PayloadAction<Assets>) => {
            state.fetchAssetsRequested = false;
            state.fetchAssetsFailed = false;
            state.assets = action.payload;
        },
        fetchAssetsFailed: (state: AppState) => {
            state.fetchGeofenceRequested = false;
            state.fetchAssetsFailed = true;
        },
        fetchGeofenceRequested: (state: AppState) => {
            state.fetchGeofenceRequested = true;
            state.fetchGeofenceFailed = false;
        },
        fetchGeofenceSucceeded: (state: AppState, action: PayloadAction<Geofence>) => {
            state.fetchGeofenceRequested = false;
            state.fetchGeofenceFailed = false;
            state.geofence = action.payload;
        },
        fetchGeofenceFailed: (state: AppState) => {
            state.fetchGeofenceRequested = false;
            state.fetchGeofenceFailed = true;
        },
        fetchGeofenceStateRequested: (state: AppState) => {
            state.fetchGeofenceStateRequested = true;
            state.fetchGeofenceStateFailed = false;
        },
        fetchGeofenceStateSucceeded: (state: AppState, action: PayloadAction<GeofenceState>) => {
            state.fetchGeofenceStateRequested = false;
            state.fetchGeofenceStateFailed = false;
            state.geofenceState = action.payload;
            state.lastSuccessfulFetchTimestamp = Date.now();
        },
        fetchGeofenceStateFailed: (state: AppState) => {
            state.fetchGeofenceStateRequested = false;
            state.fetchGeofenceStateFailed = true;
        },
        fetchGeofenceEventsRequested: (state: AppState) => {
            state.fetchGeofenceEventsRequested = true;
            state.fetchGeofenceEventsFailed = false;
        },
        fetchGeofenceEventsSucceeded: (state: AppState, action: PayloadAction<GeofenceAssetEventGroup[]>) => {
            state.fetchGeofenceEventsRequested = false;
            state.fetchGeofenceEventsFailed = false;
            state.geofenceAssetEventGroups = action.payload;
        },
        fetchGeofenceEventsFailed: (state: AppState) => {
            state.fetchGeofenceEventsRequested = false;
            state.fetchGeofenceEventsFailed = true;
        },
        setWidgetId: (state: AppState, action: PayloadAction<SetWidgetIdPayload>) => {
            state.widgetId = action.payload;
        },
        setVisibleWidgets: (state: AppState, action: PayloadAction<SetVisibleWidgetsPayload>) => {
            state.visible = action.payload.includes(state.widgetId!!);
        },
        setSelectedGeofences: (state: AppState, action: PayloadAction<SelectedGeofences>) => {
            state.selectedGeofenceIds = action.payload.ids;
        },
    },
});

export const appActions = actions;
export const appReducer = reducer;
