/* eslint-disable no-undef */
import { captureException, init } from '@sentry/browser';
import { config } from '../../config';

const release = SERVICE_VERSION;
const environment = SERVICE_ENVIRONMENT;

// should have been called before using it here
// ideally before even rendering your react app
if (environment !== 'development') {
    init({
        dsn: config.sentryToken,
        environment,
        release,
    });
}

export const reportErrorToSentry = (...args) => {
    captureException(...args);
};
