/* eslint-disable no-unused-vars */
import qs from 'qs';
import { storage } from './storage';

const PREFIX = 'featureToggle.';

const detectFeatureToggles = () => {
    const detectToggle = (transform, name) => {
        const [_, searchParams = ''] = window.location.hash.split('?');
        const params = qs.parse(searchParams);

        const toggleValue = params[name];
        const value = toggleValue ? storage.save(name, toggleValue, PREFIX) : storage.load(name, PREFIX);

        return value && transform(value);
    };

    const BooleanToggle = (value) => {
        return value === 'true' || value === '1';
    };

    return {
        enforcedEnv: detectToggle(String, 'ft_env'),
        enforcedLocale: detectToggle(String, 'ft_enforceLocale'),
        tracing: detectToggle(BooleanToggle, 'ft_tracing'),
    };
};

export const featureToggles = detectFeatureToggles();
