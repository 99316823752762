import pick from 'lodash/fp/pick';

import { Assets } from '../types';
import { Dispatch, Thunk } from '../../../types';
import { fetchData } from '../../../configuration/setup/fetch';
import { config } from '../../../config';
import { appActions } from '../reducer';

interface Link {
    href: string;
}

const exhaustPagination = async (
    startUrl: string,
): Promise<Assets> => {
    const MAX_BACKEND_CALLS = 1000;
    const items: Assets = [];
    let calls = 0;
    let nextUrl: string | undefined = startUrl;

    while (calls < MAX_BACKEND_CALLS && nextUrl) {
        calls++;
        nextUrl = await fetchData<FetchAssetsDto>(nextUrl)
            .then((response) => {
                items.push(...response.items);
                // eslint-disable-next-line no-underscore-dangle
                return response._links?.next?.href;
            });
    }

    if (calls >= MAX_BACKEND_CALLS) {
        const errorMessage = `maximal number of pages for ${startUrl} exceeded!`;
        console.error(errorMessage);
    }

    return Promise.resolve(items);
};

export const fetchAssets = (): Thunk => async (dispatch: Dispatch) => {
    try {
        dispatch(appActions.fetchAssetsRequested());
        const requestUrl = `${config.backend.ASSETS_SERVICE}/assets`;
        const assetsDto = await exhaustPagination(requestUrl);
        dispatch(appActions.fetchAssetsSucceeded(mapAssets(assetsDto)));
    } catch (e) {
        dispatch(appActions.fetchAssetsFailed());
    }
};

const mapAssets = (response: Assets): Assets => {
    return response.map(pick(['id', 'name', 'type']));
};

interface FetchAssetsDto {
    items: Assets;
    _links?: {
        self: Link;
        next?: Link;
    };
}
