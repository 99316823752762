import reduce from 'lodash/fp/reduce';
import isNil from 'lodash/fp/isNil';
import { createSelector } from 'reselect';
import { differenceInSeconds } from 'date-fns';

import { State } from '../../types';
import { AssetInfo, Assets, Geofence, GeofenceAssetEvent, GeofenceAssetEventGroup, GeofenceState } from './types';

const mapNotNil = <T, R>(transform: (iterator: T) => R | undefined | null) =>
    reduce((accumulator: R[], currentValue: T) => {
        if (!isNil(transform(currentValue))) {
            accumulator.push(transform(currentValue)!);
        }
        return accumulator;
    }, []);

export const getSessionExpiredAcknowledged = (state: State): boolean => state.app.sessionExpiredAcknowledged;
export const getWidgetId = (state: State): string | undefined => state.app.widgetId;
export const getIsWidgetVisible = (state: State): boolean => state.app.visible;
export const getAssets = (state: State): Assets | undefined => state.app.assets;
export const getSelectedGeofenceIds = (state: State): string[] => state.app.selectedGeofenceIds;
export const getFetchGeofenceRequested = (state: State): boolean => state.app.fetchGeofenceRequested;
export const getFetchGeofenceFailed = (state: State): boolean => state.app.fetchGeofenceFailed;
export const getGeofence = (state: State): Geofence | undefined => state.app.geofence;
export const getGeofenceState = (state: State): GeofenceState | undefined => state.app.geofenceState;
export const getGeofenceAssetEventGroups = (state: State): GeofenceAssetEventGroup[] =>
    state.app.geofenceAssetEventGroups;
export const getLastSuccessfulFetchTimestamp = (state: State): number | undefined => {
    const lastSuccessfulFetchTimestamp = state.app.lastSuccessfulFetchTimestamp;
    return lastSuccessfulFetchTimestamp ? lastSuccessfulFetchTimestamp : undefined;
};

export const getGeofenceStateWithAssetData = createSelector(
    getGeofenceState,
    getAssets,
    getLastSuccessfulFetchTimestamp,
    (geofenceState, assets, fetchTimestamp) => {
        if (!geofenceState) {
            return;
        }
        const mapAssetInfos = (assetInfo: AssetInfo) => {
            const matchingAsset = assets?.find((asset) => asset.id === assetInfo.assetId);
            if (!matchingAsset) {
                return;
            }
            return {
                ...assetInfo,
                assetName: matchingAsset?.name,
                assetType: matchingAsset?.type || 'truck',
                duration: fetchTimestamp && differenceInSeconds(fetchTimestamp, assetInfo.entryDate),
            };
        };
        return {
            ...geofenceState,
            assetInfoList: mapNotNil(mapAssetInfos)(geofenceState?.assetInfoList || []),
        };
    }
);

export const getGeofenceAssetEventGroupsWithAssetData = createSelector(
    getGeofenceAssetEventGroups,
    getAssets,
    (geofenceAssetEventGroups, assets) => {
        return geofenceAssetEventGroups?.map((geofenceVehicleEventGroup) => ({
            date: geofenceVehicleEventGroup.date,
            geofenceAssetEvents: mapNotNil((geofenceAssetEvent: GeofenceAssetEvent) => {
                const asset = assets?.find((element) => element.id === geofenceAssetEvent.assetId);
                if (!asset) {
                    return;
                }
                return {
                    ...geofenceAssetEvent,
                    assetName: asset?.name,
                    assetType: asset?.type,
                };
            })(geofenceVehicleEventGroup.geofenceAssetEvents),
        }));
    }
);
