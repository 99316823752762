import { Coordinates, Geofence, GeofenceType } from '../types';
import { Dispatch, Thunk } from '../../../types';
import { fetchData } from '../../../configuration/setup/fetch';
import { config } from '../../../config';
import { appActions } from '../reducer';

export const fetchGeofence =
    (geofenceId: string): Thunk =>
        async (dispatch: Dispatch) => {
            try {
                dispatch(appActions.fetchGeofenceRequested());

                const requestUrl = `${config.backend.GEOFENCES_SERVICE}/geofences/${geofenceId}`;
                const geofenceDto = await fetchData<GeofenceDto>(requestUrl);
                dispatch(appActions.fetchGeofenceSucceeded(mapGeofence(geofenceDto as GeofenceDto)));
            } catch (e) {
                dispatch(appActions.fetchGeofenceFailed());
            }
        };

const mapGeofence = (geofence: GeofenceDto): Geofence => {
    switch (geofence.type) {
        case GeofenceType.Polygon:
            return geofence as PolygonGeofenceDto;
        case GeofenceType.Circle:
            return geofence as CircleGeofenceDto;
    }
};

interface PolygonGeofenceDto {
    id: string;
    name: string;
    type: GeofenceType;
    active: boolean;
    category: string;
    points?: Coordinates[];
}

interface CircleGeofenceDto {
    id: string;
    name: string;
    type: GeofenceType;
    active: boolean;
    category: string;
    center?: Coordinates;
    radius?: number;
}

type GeofenceDto = PolygonGeofenceDto | CircleGeofenceDto;
