import React from 'react';
import { FormattedTime } from 'react-intl';

import { formatDuration } from '../../../formatUtils';
import { GeofenceAssetEvent } from '../types';

type GeofenceHistoryListGroupItemProps = GeofenceAssetEvent;

const GeofenceHistoryListGroupItem = (props: GeofenceHistoryListGroupItemProps) => {
    const { assetName, assetType, duration, entryDateTime, exitDateTime } = props;
    return (
        <div className={'display-flex justify-content-between align-items-center'}>
            <div className={'line-height-125rel display-flex align-items-center padding-right-10'}>
                <span className={`rioglyph rioglyph-${assetType?.toLowerCase() || 'truck'} margin-right-3`} />
                <span>{assetName}</span>
            </div>
            <div className={'display-flex text-color-dark text-right text-size-12 white-space-nowrap'}>
                <div className={'margin-left-10'}>
                    {duration !== undefined && (
                        <React.Fragment>
                            <span className={'rioglyph rioglyph-time-alt margin-right-2'} />
                            <span>{formatDuration(duration)}</span>
                        </React.Fragment>
                    )}
                </div>
                <div className={'margin-left-5 width-50'}>
                    {entryDateTime && (
                        <React.Fragment>
                            <span className={'rioglyph rioglyph-area-in margin-right-2'} />
                            <span className={''}>
                                <FormattedTime value={entryDateTime} hour={'2-digit'} minute={'2-digit'} />
                            </span>
                        </React.Fragment>
                    )}
                </div>
                <div className={'margin-left-5 width-50'}>
                    {exitDateTime && (
                        <React.Fragment>
                            <span className={'rioglyph rioglyph-area-out margin-right-2'} />
                            <span className={''}>
                                <FormattedTime value={exitDateTime} hour={'2-digit'} minute={'2-digit'} />
                            </span>
                        </React.Fragment>
                    )}
                </div>
                {/* leave empty to keep the space */}
            </div>
        </div>
    );
};

export default GeofenceHistoryListGroupItem;
