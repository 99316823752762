import React, { useState } from 'react';
import { FormattedPlural, useIntl } from 'react-intl';
import uniqBy from 'lodash/fp/uniqBy';
import { fromUnixTime, isSameDay, subDays } from 'date-fns';

import { GeofenceAssetEvent, GeofenceAssetEventGroup } from '../types';
import GeofenceHistoryListGroupItem from './GeofenceHistoryListGroupItem';
import GeofenceListGroupCollapsibleToggleButton from './GeofenceListGroupCollapsibleToggleButton';
import { gaPush, TRACKING_CATEGORIES } from '../../../configuration/setup/googleTagManager';

export const DEFAULT_ASSET_EVENT_COUNT = 5;

const useFormatRelativeDate = () => {
    const intl = useIntl();
    return (date: Date) => {
        const now = fromUnixTime(Date.now() / 1000);

        if (isSameDay(date, now)) {
            return intl.formatMessage({ id: 'intl-msg:events.today' });
        }

        if (isSameDay(date, subDays(now, 1))) {
            return intl.formatMessage({ id: 'intl-msg:events.yesterday' });
        }

        return intl.formatDate(date, { year: 'numeric', month: '2-digit', day: '2-digit' });
    };
};

type GeofenceHistoryListGroupProps = GeofenceAssetEventGroup;

const GeofenceHistoryListGroup = (props: GeofenceHistoryListGroupProps) => {
    const { date, geofenceAssetEvents } = props;

    const intl = useIntl();
    const formatRelativeDate = useFormatRelativeDate();
    const [expanded, setExpanded] = useState(false);

    const vehicleCount: number = uniqBy((item: GeofenceAssetEvent) => item.assetId)(geofenceAssetEvents).length;
    let eventCount: number = 0;

    geofenceAssetEvents.forEach((geofenceHistoryItem) => {
        if (geofenceHistoryItem.entryDateTime) {
            eventCount++;
        }
        if (geofenceHistoryItem.exitDateTime) {
            eventCount++;
        }
    });

    const displayedGeofenceAssetEvents =
        geofenceAssetEvents.length > DEFAULT_ASSET_EVENT_COUNT && !expanded
            ? geofenceAssetEvents.slice(0, DEFAULT_ASSET_EVENT_COUNT)
            : geofenceAssetEvents;

    const handleButtonToggle = () => {
        const targetValue = !expanded;
        setExpanded(targetValue);
        gaPush({
            category: TRACKING_CATEGORIES.GEOFENCE_DETAILS,
            action: 'Toggle Geofence Event Collapsible',
            label: `Geofence Collapsible Toggled::${targetValue ? 'Expanded' : 'Collapsed'}`,
        });
    };

    return (
        <React.Fragment>
            <div className={'list-group-item display-flex justify-content-between bg-lightest text-size-12'}>
                <span className={'text-color-dark'}>{formatRelativeDate(date)}</span>
                <div>
                    <span className={'badge bg-lighter text-color-dark text-size-10'}>
                        {`${vehicleCount} `}
                        <FormattedPlural
                            value={vehicleCount}
                            one={intl.formatMessage({ id: 'intl-msg:events.vehicle.one' })}
                            other={intl.formatMessage({ id: 'intl-msg:events.vehicle.plural' })}
                        />
                    </span>
                    <span className={'badge bg-lighter text-color-dark text-size-10 margin-left-5'}>
                        {`${eventCount} `}
                        <FormattedPlural
                            value={eventCount}
                            one={intl.formatMessage({ id: 'intl-msg:events.event.one' })}
                            other={intl.formatMessage({ id: 'intl-msg:events.event.plural' })}
                        />
                    </span>
                </div>
            </div>
            <div className="list-group-item padding-top-10 padding-bottom-10">
                {displayedGeofenceAssetEvents.map((geofenceHistoryItem, index, array) => (
                    <div
                        key={`history-list-item-${index}-${geofenceHistoryItem.assetId}`}
                        data-testid={'GeofenceEventItem'}
                    >
                        <GeofenceHistoryListGroupItem {...geofenceHistoryItem} />
                        {/* hide HR on last item */}
                        {index !== array.length - 1 && <hr className="margin-top-10 margin-bottom-10" />}
                    </div>
                ))}
                {geofenceAssetEvents.length > DEFAULT_ASSET_EVENT_COUNT && (
                    <div>
                        <hr className="margin-top-10 margin-bottom-10" />
                        <div className={'width-100pct text-center'}>
                            <GeofenceListGroupCollapsibleToggleButton
                                expanded={expanded}
                                onClick={handleButtonToggle}
                            />
                        </div>
                    </div>
                )}
            </div>
        </React.Fragment>
    );
};

export default GeofenceHistoryListGroup;
