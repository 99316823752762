import { FormattedMessage } from 'react-intl';

interface GeofenceListGroupCollapsibleToggleButtonProps {
    expanded: boolean;
    onClick: () => void;
}

const GeofenceListGroupCollapsibleToggleButton = (props: GeofenceListGroupCollapsibleToggleButtonProps) => {
    const { expanded, onClick } = props;
    return (
        <button type={'button'} className={'btn btn-primary btn-link'} onClick={onClick}>
            <span aria-hidden={'true'} className={`rioglyph rioglyph-arrow-${expanded ? 'up' : 'down'}`} />
            {expanded ? (
                <FormattedMessage id={'intl-msg:events.collapsible.toggle.button.text.collapse'} />
            ) : (
                <FormattedMessage id={'intl-msg:events.collapsible.toggle.button.text.expand'} />
            )}
        </button>
    );
};

export default GeofenceListGroupCollapsibleToggleButton;
