import React from 'react';
import { useIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import Spinner from '@rio-cloud/rio-uikit/Spinner';

import { State } from '../../../types';
import { getFetchGeofenceRequested } from '../selector';
import GeofencePropertiesContainer from './GeofenceProperties';
import { GeofenceCurrentAssetListContainer } from './GeofenceCurrentAssetList';
import GeofenceHistoryListContainer from './GeofenceHistoryList';

const WRAPPER_CLASSES = 'position-fixed display-flex flex-column justify-content-center width-100pct height-100pct';

export const GeofenceDetails = (props: Props) => {
    const intl = useIntl();

    const { fetchGeofenceRequested } = props;

    if (fetchGeofenceRequested) {
        return (
            <div className={WRAPPER_CLASSES} data-testid={'GeofenceDetailsSpinner'}>
                <Spinner text={intl.formatMessage({ id: 'intl-msg:details.loading' })} />
            </div>
        );
    }

    return (
        <React.Fragment>
            <GeofencePropertiesContainer />
            <GeofenceCurrentAssetListContainer />
            <GeofenceHistoryListContainer />
        </React.Fragment>
    );
};

type Props = ConnectedProps<typeof connector>;

const mapStateToProps = (state: State) => ({
    fetchGeofenceRequested: getFetchGeofenceRequested(state),
});

const connector = connect(mapStateToProps, {});
const GeofenceDetailsContainer = connector(GeofenceDetails);
export default GeofenceDetailsContainer;
