export const rawDataLayerPush = (val) => {
    if (window.dataLayer) {
        window.dataLayer.push(val);
    }
};
export const gaPush = ({ action, label, category }) =>
    rawDataLayerPush({
        event: 'ga_event',
        eventCategory: category,
        eventAction: action,
        eventLabel: label,
    });

// categories as defined for all services on:
// https://collaboration.msi.audi.com/confluence/display/MAN/Overarching+Topics
export const TRACKING_CATEGORIES = {
    GEOFENCE_DETAILS: 'geofenceDetails',
};

export const TRACKING_ACTIONS = {};

export const TRACKING_LABELS = {};

export const executeAndTrack = (func, val) =>
    (...args) => {
        func.apply(null, args);
        rawDataLayerPush(val);
    };

export const trackingAttributes = (category, action, label, value) => {
    const attributes = {
        'data-track-trigger': 'click',
        'data-track-category': category,
        'data-track-action': action,
    };

    if (label) {
        attributes['data-track-label'] = label;
    }

    if (value) {
        attributes['data-track-value'] = value;
    }

    return attributes;
};
