import React from 'react';
import { useIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import Notification from '@rio-cloud/rio-uikit/Notification';
import isNil from 'lodash/fp/isNil';

import { State } from '../../../types';
import { getGeofence } from '../selector';
import GeofencePropertiesRow from './GeofencePropertiesRow';
import { gaPush, TRACKING_CATEGORIES } from '../../../configuration/setup/googleTagManager';
import { Coordinates } from '../types';
import { propagateEditGeofence } from '../thunks/propagateToParent';

const getBoundingBoxCenter = (points: Coordinates[]): Coordinates => {
    const middleBy =
        <T,>(transform: (element: T) => number) =>
            (elements: T[]) =>
                (Math.min(...elements.map((it) => transform(it))) + Math.max(...elements.map((it) => transform(it)))) / 2;
    return {
        latitude: middleBy((point: Coordinates) => point.latitude)(points),
        longitude: middleBy((point: Coordinates) => point.longitude)(points),
    };
};

const copyToClipboard = (text: string) => {
    const input = document.createElement('input');
    input.style.position = 'fixed';
    input.style.opacity = '0';
    input.value = text;
    document.body.appendChild(input);
    input.select();
    document.execCommand('Copy');
    document.body.removeChild(input);
};

export const GeofenceProperties = React.memo(({ geofence, editGeofence }: Props) => {
    const intl = useIntl();

    if (!geofence) {
        return null;
    }

    const { id, type, name, active, category, center, points } = geofence;

    const classes = 'GeofenceDetails panel panel-body panel-default padding-0 overflow-hidden';

    const geofenceLabelIcon = type === 'CIRCLE' ? 'sphere' : 'polygon';

    const optionalEditButtonIcon = 'pencil';

    const address = center && center.address;

    const coordinates = center || (points && getBoundingBoxCenter(points));
    const coordinatesString = coordinates && `${coordinates.latitude.toFixed(5)}, ${coordinates.longitude.toFixed(5)}`;

    const editGeofenceHandler = () => {
        editGeofence(geofence.id);

        gaPush({
            category: TRACKING_CATEGORIES.GEOFENCE_DETAILS,
            action: 'Edit Geofence Clicked',
            label: 'Clicked Edit Geofence',
        });
    };

    const handleCopyCoordinatesButton = () => {
        if (isNil(coordinatesString)) {
            return;
        }

        copyToClipboard(coordinatesString);
        Notification.info(intl.formatMessage({ id: 'intl-msg:details.copyToClipboardInfo' }), coordinatesString);
        gaPush({
            category: TRACKING_CATEGORIES.GEOFENCE_DETAILS,
            action: 'Copy Coordinates to Clipboard Clicked',
            label: 'Clicked Copy Coordinates to Clipboard',
        });
    };

    return (
        <div className={classes} data-testid={id}>
            <div className={'margin-bottom--1'}>
                <GeofencePropertiesRow
                    labelIcon={geofenceLabelIcon}
                    labelIntlId={'intl-msg:details.caption'}
                    text={name}
                    buttonIcon={optionalEditButtonIcon}
                    onButtonClick={editGeofenceHandler}
                />
                <GeofencePropertiesRow
                    labelIntlId={'intl-msg:details.active'}
                    text={
                        active
                            ? intl.formatMessage({ id: 'intl-msg:details.yes' })
                            : intl.formatMessage({ id: 'intl-msg:details.no' })
                    }
                />
                {address && <GeofencePropertiesRow labelIntlId={'intl-msg:details.address'} text={address} />}
                {coordinatesString && (
                    <GeofencePropertiesRow
                        labelIntlId={'intl-msg:details.coordinates'}
                        text={coordinatesString}
                        buttonIcon={'duplicate'}
                        onButtonClick={handleCopyCoordinatesButton}
                    />
                )}
                {category && (
                    <GeofencePropertiesRow
                        labelIntlId={'intl-msg:details.category'}
                        text={<span className={'label label-default label-condensed'}>{category}</span>}
                    />
                )}
            </div>
        </div>
    );
});

type Props = ConnectedProps<typeof connector>;

const mapStateToProps = (state: State) => ({
    geofence: getGeofence(state),
});
const mapDispatchToProps = (dispatch: ThunkDispatch<State, void, AnyAction>) => ({
    editGeofence: (id: string) => {
        dispatch(propagateEditGeofence(id));
    },
});

const connector = connect(mapStateToProps, mapDispatchToProps);
const GeofencePropertiesContainer = connector(GeofenceProperties);
export default GeofencePropertiesContainer;
