import { ConfigState } from './types';

const asBool = (value: string | undefined): boolean => value === 'true';

export const config: ConfigState = {
    backend: {
        AUTHENTICATION_SERVICE: import.meta.env.VITE_AUTHENTICATION_SERVICE,
        ASSETS_SERVICE: import.meta.env.VITE_ASSETS_SERVICE,
        GEOFENCES_SERVICE: import.meta.env.VITE_GEOFENCES_SERVICE,
        GEOFENCE_EVENTS_SERVICE: import.meta.env.VITE_GEOFENCE_EVENTS_SERVICE,
    },
    homeRoute: import.meta.env.VITE_HOME_ROUTE,
    id: import.meta.env.VITE_ID,
    login: {
        authority: import.meta.env.VITE_LOGIN_AUTHORITY,
        clientId: '334dfbd7-19a9-439a-a8c1-7e43cf517f65',
        oauthScope: ['openid', 'profile', 'email', 'geofence.read', 'asset-administration.read'],
        mockUser: import.meta.env.VITE_LOGIN_MOCK_USER,
        mockAuthorization: asBool(import.meta.env.VITE_LOGIN_MOCK_AUTHORIZATION),
        mockLocale: import.meta.env.VITE_LOGIN_MOCK_LOCALE,
        preventRedirect: asBool(import.meta.env.VITE_LOGIN_PREVENT_REDIRECT),
        redirectUri: import.meta.env.VITE_LOGIN_REDIRECT_URI,
        silentRedirectUri: import.meta.env.VITE_LOGIN_SILENT_REDIRECT_URI,
    },
    logoutUri: import.meta.env.VITE_LOGOUT_URI,
    sentryToken: 'https://c611682c0fa04205af3411be8ad167db@o117480.ingest.sentry.io/5246232',
};
