const localStorage = window.localStorage;

const STORAGE_PREFIX = 'history.';

const save = (key, value, prefix = STORAGE_PREFIX) => {
    try {
        localStorage.setItem(`${prefix}${key}`, JSON.stringify(value));
        return value;
    } catch (_) {
        // Intentionally left blank
    }
};

const load = (key, prefix = STORAGE_PREFIX) => {
    try {
        return JSON.parse(localStorage.getItem(`${prefix}${key}`));
    } catch (_) {
        // Intentionally left blank
    }
};

export const storage = { save, load };
