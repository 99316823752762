import { gaPush, TRACKING_CATEGORIES } from '../../configuration/setup/googleTagManager';
import OverlayTrigger from '@rio-cloud/rio-uikit/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/Tooltip';
import { useIntl } from 'react-intl';
import { Dispatch, State } from '../../types';
import { getSelectedGeofenceIds } from './selector';
import { batch, connect, ConnectedProps } from 'react-redux';
import { fetchGeofenceState } from './fetchData/fetchGeofenceState';
import { fetchGeofenceEvents } from './fetchData/fetchGeofenceEvents';

export const RefreshButton = (props: Props) => {
    const { refresh, selectedGeofenceIds } = props;
    const intl = useIntl();

    const handleRefresh = () => {
        refresh(selectedGeofenceIds[0]);
        gaPush({
            category: TRACKING_CATEGORIES.GEOFENCE_DETAILS,
            action: 'Click on Refresh Button',
            label: 'Click on Refresh Button',
        });
    };

    return (
        <OverlayTrigger
            placement={'bottom'}
            overlay={
                <Tooltip id={'tooltip'} width={'auto'}>
                    {intl.formatMessage({ id: 'intl-msg:tooltip.refresh' })}
                </Tooltip>
            }
        >
            <button
                className={'btn btn-muted btn-xs btn-icon-only hover-bg-none'}
                onClick={handleRefresh}
                data-testid="refreshButton"
            >
                <span className={'rioglyph rioglyph-refresh text-color-white'} />
            </button>
        </OverlayTrigger>
    );
};

const mapStateToProps = (state: State) => ({
    selectedGeofenceIds: getSelectedGeofenceIds(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    refresh: (geofenceId: string) =>
        batch(() => {
            dispatch(fetchGeofenceState(geofenceId));
            dispatch(fetchGeofenceEvents(geofenceId));
        }),
});

type Props = ConnectedProps<typeof connector>;

const connector = connect(mapStateToProps, mapDispatchToProps);
const RefreshButtonContainer = connector(RefreshButton);
export default RefreshButtonContainer;
