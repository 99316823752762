/* eslint-disable camelcase */
import {GeofenceState} from '../types';
import {Dispatch, Thunk} from '../../../types';
import {fetchData} from '../../../configuration/setup/fetch';
import {config} from '../../../config';
import {parseISO} from 'date-fns';
import {appActions} from '../reducer';

export const fetchGeofenceState =
    (geofenceId: string): Thunk =>
        async (dispatch: Dispatch) => {
            try {
                dispatch(appActions.fetchGeofenceStateRequested());

                const requestUrl = `${config.backend.GEOFENCE_EVENTS_SERVICE}/geofences/${geofenceId}/state`;
                const geofenceStateDto = await fetchData<GeofenceStateDto>(requestUrl);
                dispatch(appActions.fetchGeofenceStateSucceeded(mapGeofenceState(geofenceStateDto as GeofenceStateDto)));
            } catch (e) {
                dispatch(appActions.fetchGeofenceStateFailed());
            }
        };

const mapGeofenceState = (geofenceState: GeofenceStateDto): GeofenceState => {
    return {
        exceptionAssetIds: [],
        geofenceId: geofenceState.geofence_id,
        assetInfoList: geofenceState.assets_in_geofence.map((asset) => ({
            assetId: asset.asset_id,
            entryDate: parseISO(asset.entry_date_time),
        })),
        warningAssetIds: [],
    };
};

export interface AssetEntryDto {
    asset_id: string;
    entry_date_time: string;
}

interface GeofenceStateDto {
    geofence_id: string;
    assets_in_geofence: AssetEntryDto[];
}
