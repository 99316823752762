import { FormattedPlural, useIntl } from 'react-intl';
import { GeofenceCurrentAssetListItem } from './GeofenceCurrentAssetListItem';
import RefreshButtonContainer from '../RefreshButtonContainer';
import { connect, ConnectedProps } from 'react-redux';
import { State } from '../../../types';
import { getGeofenceStateWithAssetData } from '../selector';

export const GeofenceCurrentAssetList = (props: Props) => {
    const intl = useIntl();

    const assetInfoList = props.geofenceState?.assetInfoList;
    return (
        <div className="panel panel-primary border-color-map-marker-geofence">
            <div className="panel-heading bg-map-marker-geofence border-color-map-marker-geofence display-flex justify-content-between">
                <span>
                    <span>{`${intl.formatMessage({ id: 'intl-msg:currentAssets.caption' })} `}</span>
                    <RefreshButtonContainer />
                </span>
                <div>
                    <span className="badge bg-white text-color-dark text-size-10">
                        {`${assetInfoList?.length || 0} `}
                        <FormattedPlural
                            value={assetInfoList?.length || 0}
                            one={intl.formatMessage({ id: 'intl-msg:currentAssets.vehicle.one' })}
                            other={intl.formatMessage({ id: 'intl-msg:currentAssets.vehicle.plural' })}
                        />
                    </span>
                </div>
            </div>
            <div className="panel-body">
                {assetInfoList?.map((item, index) => (
                    <div key={`geofence-asset-${index}`}>
                        <GeofenceCurrentAssetListItem {...item} />
                        {index !== assetInfoList.length - 1 && <hr className="margin-top-10 margin-bottom-10" />}
                    </div>
                ))}
            </div>
        </div>
    );
};

type Props = ConnectedProps<typeof connector>;

const mapStateToProps = (state: State) => ({
    geofenceState: getGeofenceStateWithAssetData(state),
});

const connector = connect(mapStateToProps);
export const GeofenceCurrentAssetListContainer = connector(GeofenceCurrentAssetList);
