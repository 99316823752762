import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { sendMessage } from '../MessageHandler';

import { State } from '../../../types';
import { editGeofenceType } from '../remoteActionTypes';

export const propagateEditGeofence =
    (id: string): ThunkAction<void, State, unknown, Action<string>> =>
        () => {
            sendMessage({ type: editGeofenceType, payload: { id } });
        };
