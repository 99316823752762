/* eslint-disable camelcase */
import {Dispatch, Thunk} from '../../../types';
import {fetchData} from '../../../configuration/setup/fetch';
import {config} from '../../../config';
import {mapGeofenceAssetEventGroups} from './mapGeofenceAssetEventGroups';
import {appActions} from '../reducer';

export const fetchGeofenceEvents =
    (geofenceId: string): Thunk =>
        async (dispatch: Dispatch) => {
            try {
                dispatch(appActions.fetchGeofenceEventsRequested());
                const requestUrl = `${config.backend.GEOFENCE_EVENTS_SERVICE}/geofences/${geofenceId}/event-aggregates`;
                const geofenceEventsResponse = await fetchData<GeofenceEventsResponse>(requestUrl);
                dispatch(
                    appActions.fetchGeofenceEventsSucceeded(
                        mapGeofenceAssetEventGroups(geofenceEventsResponse as GeofenceEventsResponse),
                    ),
                );
            } catch (e) {
                dispatch(appActions.fetchGeofenceEventsFailed());
            }
        };

export type GeofenceEventsDto = GeofenceEventDto[];

export interface GeofenceEventsResponse {
    events: GeofenceEventDto[];
}

interface GeofenceEventDto {
    asset_id: string;
    geofence_id: string;
    entry_date_time: string;
    exit_date_time?: string;
    duration?: number;
}
