import { useState } from 'react';
import { useIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import ClearableInput from '@rio-cloud/rio-uikit/ClearableInput';
import CustomState from '@rio-cloud/rio-uikit/CustomState';

import { State } from '../../../types';
import { getFetchGeofenceRequested, getGeofenceAssetEventGroupsWithAssetData } from '../selector';
import GeofenceHistoryListGroup from './GeofenceHistoryListGroup';

export const GeofenceHistoryList = (props: Props) => {
    const { geofenceAssetEventGroups, loadingEvents } = props;
    const intl = useIntl();
    const [searchText, setSearchText] = useState('');

    if (!loadingEvents && geofenceAssetEventGroups.length === 0) {
        return (
            <CustomState
                icon={'rioglyph-geofence'}
                headline={intl.formatMessage({ id: 'intl-msg:events.nodata.headline' })}
                message={intl.formatMessage({ id: 'intl-msg:events.nodata.message' })}
                outerClassName={'margin-bottom-20'}
                condensed={true}
            />
        );
    }

    const filteredGeofenceEventGroups = geofenceAssetEventGroups
        .map((group) => ({
            date: group.date,
            geofenceAssetEvents: group.geofenceAssetEvents.filter((event) =>
                event.assetName?.toLowerCase().includes(searchText.toLowerCase())
            ),
        }))
        .filter((group) => group.geofenceAssetEvents.length !== 0);

    return (
        <div className={'list-group'}>
            <div className={'list-group-item padding-10'}>
                <div className={'input-group'}>
                    <span className={'input-group-addon'}>
                        <span className={'rioglyph rioglyph-filter'} />
                    </span>
                    <ClearableInput
                        type={'text'}
                        value={searchText}
                        placeholder={intl.formatMessage({ id: 'intl-msg:events.filter.textfield.placeholder.text' })}
                        onChange={setSearchText}
                    />
                </div>
            </div>
            {filteredGeofenceEventGroups.map((group) => (
                <GeofenceHistoryListGroup key={`geofence-history-group-${group.date}`} {...group} />
            ))}
        </div>
    );
};

type Props = ConnectedProps<typeof connector>;

const mapStateToProps = (state: State) => ({
    geofenceAssetEventGroups: getGeofenceAssetEventGroupsWithAssetData(state),
    loadingEvents: getFetchGeofenceRequested(state),
});

const connector = connect(mapStateToProps, {});

export default connector(GeofenceHistoryList);
